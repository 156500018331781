
@import "~easytecq-css/scss/functions";
@import "~easytecq-css/components/pageOffer";

.eo-table {
  .offer-terms {
    padding: 5px 0 0 0;
    font-size: .7rem;
  }
}

.eo-dialog .eo-sportsbook-list {
  margin: 10px;
}

.sportsbook-terms {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  padding-left: var(--space-2x);
  padding-right: var(--space-2x);
  font-size: var(--text-xs);
  text-wrap: balance;
  svg { fill: var(--color-positive); }
  @media (min-width: 768px) { 
    padding-left: var(--space-3x);
    padding-right: var(--space-3x);
  }
  @media (max-width: 767px) {
    grid-row-start: 5;
    grid-row-end: 6;
    color: var(--color-neutral);
    z-index: 1;
    padding-bottom: 10px;
  }
}

.sportsbook-terms-popup {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
  padding-left: var(--space-2x);
  padding-right: var(--space-2x);
  font-size: var(--text-xs);
  text-wrap: balance;
  text-align: center;
  svg { fill: var(--color-positive); }
  @media (min-width: 768px) { 
    padding-left: var(--space-3x);
    padding-right: var(--space-3x);
  }
  @media (max-width: 767px) {
    grid-row-start: 5;
    grid-row-end: 6;
    color: var(--color-neutral);
    z-index: 1;
    padding-bottom: 10px;
    padding-left: var(--space-4x);
    padding-right: var(--space-4x);
  }
}

.eo-pageOffer {
  border: none !important;
}

.sportsbook-play-popup {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  padding: 16px;
}

@media (min-width: 768px) {
  .sportsbook-play-popup {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 0px 16px;
  }
}

.eo-sportsbook.typ-offer.m-spotlight .sportsbook-offerTitle-popup {
  padding: 16px 24px 0px;
}

.eo-sportsbook.typ-offer {
  padding: 4px 16px;
}

.sportsbook-offerTitle-popup {
  align-self: end;
  box-sizing: border-box;
  display: flex;
  padding: 0px 24px; /* shorthand for padding: top right bottom left */
  visibility: visible;
  z-index: 1;
  font-family: "Fira Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  tab-size: 4;
  text-align: center;
  text-size-adjust: 100%;
  text-wrap: balance;
  color: rgb(55, 49, 67);
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-top: 12px;
  vertical-align: text-bottom;
}
html[data-eo-theme="dark"] {
  .sportsbook-offerTitle-popup {
    color: #fff;
  }
}

/* For screens larger than 768px */
@media (min-width: 768px) {
  .sportsbook-offerTitle-popup {
    align-self: end;
    box-sizing: border-box;
    display: flex;
    visibility: visible;
    z-index: 1;
    font-family: "Fira Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    tab-size: 4;
    text-align: center;
    text-size-adjust: 100%;
    text-wrap: balance;
    color: rgb(55, 49, 67);
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 12px;
    vertical-align: text-bottom;
  }
}

.popup {
  margin-bottom: var(--space-1x);
}