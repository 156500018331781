.navMain {
  &-item {
    &-container {
      display: flex;
      position: relative;
    }
    &__subNav {
      top: 40px;
      left: 0;
      position: absolute;
      display: none;
      &.m-visible {
        display: inline-block;
      }
      ul {
        background:var(--color-bg);
        box-shadow: 0px 4px 4px rgba(0,0,0,.5);
        border-bottom-left-radius: var(--round-sm);
        border-bottom-right-radius: var(--round-sm);
        margin: 5px;
        padding: 10px;
        list-style: none;
        white-space: nowrap;
        a {
          padding: 2px 5px;
        }
      }
    }
  }
}