@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
@import "~easytecq-css/scss/reset";
@import "~easytecq-css/scss/vars";
@import "~easytecq-css/scss/functions";
@import "~easytecq-css/scss/base";
@import "~easytecq-css/scss/layout";
@import "~easytecq-css/components/navFull";
@import "~easytecq-css/components/navMain";
@import "~easytecq-css/components/navSecondary";
@import "~easytecq-css/components/navHeader";
@import "~easytecq-css/components/navPage";
@import "~easytecq-css/components/infoBanner";
@import "~easytecq-css/components/profileSection";
@import "~easytecq-css/scss/spinner";
@import "easytecq-css/components/navFooter";
@import "easytecq-css/components/navSocial";
@import "../styles/navigation";

.eo-navHeader .eo-button {
  align-self: baseline;
  cursor: pointer;
  margin-top: 6px;
}

.section-content {
  p, h2, h3, h4, h5 {
    padding: 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .eo-navFooter {
    gap: unset;
    flex-direction: column;
    flex: unset !important;
  }
  .footer-wrap .footer-top {
    flex-direction: row !important;
    .eo-navSocial {
      flex-direction: column;
    }
  }
}

.navFooter-item {
  .icon-spacer {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.block-section {
  &__image {
    margin-bottom: 15px;
  }
}